.containerDiv {
    position: relative;
    width: 100%;
  }
  
  .imageMediaCenter {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .overlayMediaCenter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(74, 71, 71, 0);
    color: #ffffff;
    font-family: 'Quicksand', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
  }
  .overlayMediaCenter > *{
    position: absolute;
    top: 2px;
    right: 3px;
    cursor: pointer;
    transition: transform 0.25s;
  }
  
  .containerDiv:hover .overlayMediaCenter {
    transform: translateY(0);
    opacity: 1;
  }

  .containerDiv:hover .imageMediaCenter{
    opacity: 0.3;
  }

  .textMediaCenter {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 1 rem;
    position: absolute;
    padding: 3px;
    border-radius: 2px;
    cursor: pointer;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .buttonPreviewHover{
    display: none;
  }

  .imagePreviewContainer :hover .buttonPreviewHover{
    display: flex;
    justify-content: center;
    border: 0.5px solid red;
    border-radius: 3px;
    color: red;
    background-color: transparent;
    cursor: pointer;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    transition: ease-in 5s;
  }

  .buttonDeleteUploadedImage{
    display: none;
  }

  .boxDisplayFilterData:hover .buttonDeleteUploadedImage{
    display: inherit;
    position: absolute;
    z-index: 100;
  }

  .buttonDeleteUploadedImage :hover{
    transform: scaleY(1.1);
    color: black;
  }

  .buttonDeleteUploadedImage .MuiButton-root{
    color: white;
  }


