.logo-mpi {
    margin-top: 5px;
    img {
        width: 100px;
    }
    flex-grow: 1;
    background-color: #0091d6;
}

.logo-mpi-p {
    margin-right: 20px;
    margin-top: 10px;
    margin-left: 0px !important;
    img {
        width: 30px;
    }
}

.logo-mpi-main {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    img {
        width: 100px;
    }
}

.right-navbar {
    display: flex;
    cursor: pointer;
    color: #fff;
}

.text-navbar {
    a {
        color: #e8f0f5;
    }
}

.popper-style {
    margin-top: 20px;
}

// .sidebar-navbar {
//     padding-top: 10px;
//     ul {
//         list-style: none;
//         margin: 0;
//         background-color: #fff;
//         display: flex;
//         li {
//             span {
//                 color: #000;
//             }
//             padding: 6px
//         }
//         .navbar-list {
//             display: flex;
//             align-items: center;
//             .icon {
//                 color: #0091d6;
//                 margin-right: 10px;
//                 margin-top: 5px;
//             }
//         }
//     }
//     }
//     .active-link {
//         ul {
//             background-color: #0091d6;
//             li {
//                 span {
//                     color: #fff;
//                 }
//             }
//             .navbar-list {
//                 display: flex;
//                 align-items: center;
//                 .icon {
//                     color: #fff;
//                     margin-right: 10px;
//                     margin-top: 5px;
//                 }
//             }
//         }
        
//     }
    

    
// .sidebar-navbar-dropdown {
//     padding-top: 15px;
//     .menu-dropdown {
//         padding-left: 45px;
//         padding-right: 50px;
//         padding-bottom: 5px;
//         cursor: pointer;
//     }
//     .menu-list-dropdown {
//         display: none;
//     }
//     .menu-list-dropdown-active {
//         display: block;
//         .navbar-list {
//             padding-left: 20px;        
//         }
//     }
// }


.sidebar-navbar {
    font-size: 13px;
    ul {
        padding-left: 20px;
        list-style: none;
        margin: 0;
        background-color: #fff;
        display: flex;
        li {
            span {
                color: #000;
            }
            padding: 6px
        }
        .navbar-list {
            display: flex;
            align-items: center;
            .icon {
                color: #0091d6;
                margin-right: 10px;
                margin-top: 5px;
            }
        }
    }
}
.active-link {
    ul {
        border-right: 5px solid #0091d6;
        li {
            span {
                color: #0091d6;
                font-weight: 600;
            }
        }
        .navbar-list {
            display: flex;
            align-items: center;
            .icon {
                color: #fff;
                margin-right: 10px;
                margin-top: 5px;
            }
        }
        
    }
}
    

.sidebar-navbar-dropdown {
    padding-top: 15px;
    font-size: 13px;
    .menu-dropdown {
        padding-left: 25px;
        padding-right: 27px;
        padding-bottom: 5px;
        cursor: pointer;
    }
    .menu-list-dropdown {
        display: none;
    }
    .menu-list-dropdown-active {
        display: block;
        .navbar-list-child {
            padding-left: 12px;  
            display: flex;
            align-items: center;
            font-size: 13px;
            .icon {
                color: #fff;
                margin-right: 10px;
                margin-top: 5px;;
            }         
        }
    }
}