body {
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none !important;
}

.overhiddenflow ::-webkit-scrollbar {
    display: none;
}
.global-container {
    padding-left: 10px;
    padding-right: 10px;
}

.container-height {
    margin-top: 20px;
    padding-top: 40px;
    min-height: 70vh;
    margin-bottom: 50px;
    h1 {
        color: #0091d6;
    }
}

.container-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: #f9fafc;
    background-size: cover;
    height: 100vh;
}

.name-login {
    h1 {
        padding-bottom: 5px;
        color: #0091d6;
    }
}

.ck.ck-editor__main>.ck-editor__editable {
    min-height: 170px;
}

.container-footer {
    width: 100%;
}

.copyright-text {
    text-align: center;
    color: #092c4c;
}

.error-p {
    color: #d43232;
    padding-left: 5px;
    padding-top: 0px;
    font-size: 13px;
}

.box-modal-create {
    min-height: 500px;
    margin-bottom: 20px;
    padding: 15px;
}

.box-modal-modules {
    min-height: 500px;
    margin-bottom: 20px;
    padding: 15px;
}

#select-style-searchby {
    z-index: 150 !important
}

#select-style-value {
    z-index: 150 !important
}

#select-style-cat {
    z-index: 250 !important;
}

#select-style-type {
    z-index: 240 !important;
}

#select-style-province {
    z-index: 30 !important;
}

#select-style-city {
    z-index: 20 !important;
    text-transform: capitalize;
}

#select-style-district {
    z-index: 10 !important;
    text-transform: capitalize;
}

#select-style-countryy {
    z-index: 99 !important;
}


#select-style-provincee {
    z-index: 98 !important;
}

#select-style-cityy {
    z-index: 97 !important;
}


#select-style-subdistrict {
    z-index: 95 !important;
}

#select-style-village {
    z-index: 93 !important;
}

#select-style-zipcode {
    z-index: 92 !important;
}
#select-style-cat-product-0 {
    z-index: 55 !important;
}
#select-style-cat-product-1 {
    z-index: 50 !important;
}
#select-style-cat-product-2 {
    z-index: 49 !important;
}
#select-style-cat-product-3 {
    z-index: 48 !important;
}
#select-style-cat-product-4 {
    z-index: 47 !important;
}
#select-style-cat-product-5 {
    z-index: 46 !important;
}
#select-style-cat-product-6 {
    z-index: 45 !important;
}

#select-style-brand-product {
    z-index: 40 !important;
}

#select-style-mes-product {
    z-index: 30 !important;
}

#select-style-warehouse-product {
    z-index: 20 !important;
}

#select-roles {
    z-index: 10 !important;
}


#select-style-coverage-0 {
    z-index: 100 !important;
}

#select-style-coverage-1 {
    z-index: 99 !important;
}
#select-style-coverage-2 {
    z-index: 98 !important;
}
#select-style-coverage-3 {
    z-index: 97 !important;
}
#select-style-coverage-4 {
    z-index: 96 !important;
}
#select-style-coverage-5 {
    z-index: 95 !important;
}

#select-style-coverage-6 {
    z-index: 94 !important;
}
#select-style-coverage-7 {
    z-index: 93 !important;
}
#select-style-coverage-9 {
    z-index: 92 !important;
}

#select-style-warehouse-product-0 {
    z-index: 100 !important;
}
#select-style-warehouse-product-1 {
    z-index: 99 !important;
}
#select-style-warehouse-product-2 {
    z-index: 98 !important;
}
#select-style-warehouse-product-3 {
    z-index: 97 !important;
}
#select-style-warehouse-product-4 {
    z-index: 96 !important;
}
#select-style-warehouse-product-5 {
    z-index: 95 !important;
}
#select-style-warehouse-product-6 {
    z-index: 94 !important;
}
#select-style-warehouse-product-7 {
    z-index: 93 !important;
}
#select-style-warehouse-product-9 {
    z-index: 902 !important;
}
#select-style-warehouse-product-10 {
    z-index: 900 !important;
}
#select-style-warehouse-product-11 {
    z-index: 809 !important;
}
#select-style-warehouse-product-12 {
    z-index: 088 !important;
}
#select-style-warehouse-product-13 {
    z-index: 807 !important;
}
#select-style-warehouse-product-14 {
    z-index: 806 !important;
}
#select-style-warehouse-product-15 {
    z-index: 805 !important;
}
#select-style-warehouse-product-16 {
    z-index: 804 !important;
}
#select-style-warehouse-product-17 {
    z-index: 803 !important;
}
#select-style-warehouse-product-18 {
    z-index: 802 !important;
}
#select-style-warehouse-product-19 {
    z-index: 801 !important;
}
#select-style-warehouse-product-20 {
    z-index: 800 !important;
}


  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  }

  /* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  }


#ce-add-username-input {
    display: none !important;
}

#ce-options-drop-down {
    display: none !important;
}
#ce-delete-chat-button {
    display: none !important;
}

#new-chat-plus-button {
	display: none !important;
}

.ce-chat-form-container {
    margin-bottom: 35px !important;
}

#img-review-proof {
    cursor: pointer;
}