.paper-box {
    width: 200px;
    height: 100px;
    background-color: red;
}

.rdt_TableHeadRow {
	border-radius: 5px;
}

.rdt_TableCol {
    font-weight: 500;
    color: azure;
    font-size: 13px;
	text-transform: capitalize;
    background-color: #0091d6;
	color: #fff;
    height: 50px;
}

.rdt_TableCell {
    height: 45px;
    font-size: 12px;
}

.jsAMOb {
	height: 70px !important;
	background-color: red;
}

.text-notfound {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        margin-bottom: 20px;
    }
}

.card-statistic {
	h4 {
		font-weight: 300;
		color: #000;
		font-family: 'Roboto', sans-serif;
		letter-spacing: 1px;
	}
    h3 {
        font-weight: 400;
		font-size: 25px;
		color: #0091d6;
		padding-top: 5px;
    }
	img {
		width: 50px;
	}
	display: flex;
	justify-content: space-between;
}

.card-statistic-status {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #000;
    h4 {
        font-weight: 500;
    }
    h1 {
        font-size: 30px;
    }
}


.floating-button {
    // position: absolute;
    font-size: 14px;
    bottom: 0;
    left: 0;
    margin-top: 30px;
    margin-bottom: 10px;
}


.loading-button {
    display: flex;
    align-items: center;
    p {
        margin-right: 5px;
    }
}

.menu-pop-over {
    margin-top: 5px;
    .box-menu {
        max-width: 500px;
		max-height: 470px;
        list-style: none;
        padding: 10px;
        margin: 0;
    }
}

.paper-table-product {
	width: 82vw;
}


// phone code input 
/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: inherit;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
    height: 50px;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
    height: 40px;
    border: 1px solid #ccc;
    font-size: 15px;
    padding-left: 10px;
    margin-left: 5px;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
    margin-right: 7px;
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: 12px;
	height: 12px;
    color: #000;
	margin-left: 2px;
	border-style: solid;
	border-color: #000;
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.container-cattree {
	position: relative;
}

.input-select-tree {
	display: flex;
	width: 100%;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #ccc;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	justify-content: space-between;
}

.icon-tree {
	padding-top: 5px;
}

.input-select-tree > p {
	padding-top: 8px;
	border: none;
}

.content-catree {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	padding: 10px;
	z-index: 200;
	position: absolute;
	width: 100%;
	height: 230px;
	display: flex;
	padding-bottom: 20px;
}

.box-tree {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	width: 100%;
	padding-right: 5px;
	// border: 1px solid red;
}

.content-box {
	border-right: 1px solid #ccc;
	// border-left: 1px solid #ccc;
	overflow-y: auto;
	margin-left: 5px;
	// margin-right: 5px;

}

.box-tree > p {
	padding-left: 5px;
}
.content-box-item {
	padding: 2px;
	margin-bottom: 2px;
}

.active-tree {
	border-left: 2px solid #0091d6;
}

.content-box-item:hover {
	background-color: #bbdefb;
	border-radius: 5px;
}

.box-tree-selected {
	display: flex;
	flex-direction: row;
}

.title-tree-selected {
	display: flex;
	flex-direction: row;
	padding-top: 8px;
}

.title-tree-selected > p {
	padding-right: 5px;
}

.title-tree-selected > span {
	padding-right: 5px;
}